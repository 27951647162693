import React, { useEffect, useState } from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import { SwipeableDrawer } from "@mui/material";
import DataService, {
  ADMIN_IMAGE_ENDPOINT,
} from "../../../../config/DataService";
import { getClientList } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import Loader from "../../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";
import EditNewVendor from "./vendor-manage-modal/EditNewVendor";
import AddNewVendor from "./vendor-manage-modal/AddNewVendor";
import VendorDeleteModal from "./vendor-manage-modal/VendorDeleteModal";
import VendorRestrict from "./vendor-manage-modal/VendorRestrict";

function VendorManagement(props) {
  const [isActive, setActive] = useState(Array(0).fill(false));
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  // Pagination states and methods
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // role permission
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;

  const toggleMenuClass = (index) => {
    const updatedStates = [...isActive];
    updatedStates[index] = !updatedStates[index];
    setActive(updatedStates);
  };

  const dispatch = PagesIndex.useDispatch();

  // add user modal
  const [open, setOpen] = useState(false);
  const [userListData, setUserListData] = useState(false);
  const [editUserData, setEditUserData] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  console.log(filteredData, "filteredData");

  // Add user Modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditUserData("");
  };
  // Edit User Details modal
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleEditOpen = () => {
    setOpenEditModal(true);
  };
  const handleCloseEdit = () => setOpenEditModal(false);

  // Restrict modal
  const [restrictUserStatus, setRestrictUserStatus] = useState(false);
  const [restrictUserModalopen, setRestrictUserModalOpen] = useState(false);
  const restrictUserhandleOpen = (userId, status) => {
    const data = {
      userId: userId,
      status: !status,
    };
    setRestrictUserModalOpen(true);
    setRestrictUserStatus(data);
  };
  const restrictUserhandleClose = () => {
    setRestrictUserModalOpen(false);
  };

  // Delete functionality
  const [deleteUserId, setDeleteUserId] = useState([]);
  const [openDeleteNew, setOpenDeleteNew] = useState(false);
  const handleOpenDeleteNew = () => setOpenDeleteNew(true);
  const handleCloseDeleteNew = () => setOpenDeleteNew(false);

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // get user list
  const getUserListData = () => {
    setLoading(true);
    dispatch(getClientList()).then((res) => {
      if (res?.payload?.status == 200) {
        setUserListData(res?.payload?.data);
        setFilteredData(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  PagesIndex.useEffect(() => {
    getUserListData();
  }, []);

  // search
  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
    const filteredList = userListData?.filter((data) =>
      `${data?.name?.toLowerCase()} ${data?.mobileNumber
        }  ${data?.email?.toLowerCase()}`?.includes(e?.target?.value.toLowerCase())
    );
    setFilteredData(filteredList);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title"
              component="h2"
              variant="h2"
            >
              Vendor Management
            </Index.Typography>

            {/* Top search */}
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      value={searchValue}
                      onChange={handleSearch}
                      onKeyDown={(e) => {
                        if (
                          e.key === " " &&
                          e.target.value.trim() === ""
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <img
                      src={PagesIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {/* top Filter */}
              {/* <Index.Box className="filter-main">
                {["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Index.Box className="export-btn-main border-btn-main">
                      <Index.Button
                        className="export-btn border-btn"
                        onClick={toggleDrawer(anchor, true)}
                      >
                        <img
                          src={PagesIndex.Svg.filter}
                          className="down-icon"
                          alt="download icon"
                        />
                        Filter
                      </Index.Button>
                    </Index.Box>
                    <SwipeableDrawer
                      className="filter-main"
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      <Index.Box className="filter-header">
                        <Index.Typography className="filter-title">
                          User Filter
                        </Index.Typography>
                        <img
                          src={PagesIndex.Svg.closeicon}
                          className="filter-close-icon"
                          onClick={toggleDrawer(anchor, false)}
                          alt=""
                        />
                      </Index.Box>
                      <Index.Box className="filter-inner-main">
                        <Index.Box className="input-box filter-input-box">
                          <Index.FormHelperText className="form-lable">
                            Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder=""
                            />
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box filter-input-box">
                          <Index.FormHelperText className="form-lable">
                            City
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder=""
                            />
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box filter-input-box">
                          <Index.FormHelperText className="form-lable">
                            Status
                          </Index.FormHelperText>
                          <Index.Box className="checkbox-main filter-checkbox-main input-box">
                            <Index.FormControlLabel
                              control={<Index.Checkbox defaultChecked />}
                              label="Active"
                              className="checkbox-lable"
                            />
                            <Index.FormControlLabel
                              control={<Index.Checkbox />}
                              label="Deactivate"
                              className="checkbox-lable"
                            />
                            <Index.FormControlLabel
                              control={<Index.Checkbox />}
                              label="Pending"
                              className="checkbox-lable"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="filter-footer">
                        <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                          <Index.Button className="border-btn filter-cancel-btn">
                            Cancel
                          </Index.Button>
                          <Index.Button className="btn-primary filter-btn">
                            Filter
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </Index.Box> */}
              {/* Add user Button */}
              <Index.Box className="userlist-inner-btn-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {roleName === "Agency" || roleName === "Department" ||
                    AgencyPermission?.Client?.permission?.add == true ? (
                    <Index.Button
                      className="adduser-btn btn-primary"
                      onClick={() => {
                        handleOpen();
                      }}
                    >
                      <img
                        src={PagesIndex.Svg.plus}
                        className="plus-icon"
                        alt="plus icon"
                      />
                      Add Vendor
                    </Index.Button>
                  ) : null}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Edit User Details End */}
          <Index.Box className="vendor-managment-sec">
            <>
              <Index.Box className="client-manage-sec">
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <Index.Box className="card-main">
                      <Index.Box className="card-left">
                        <Index.Box className="officer-profile-flex">
                          <img
                            src={
                              item?.image
                                ? // ? `${ADMIN_IMAGE_ENDPOINT}${item?.image}`
                                `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                                : PagesIndex.Png.usericon
                            }
                            alt=""
                            className="prof-img"
                          />
                          <Index.Box className="officer-detail">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="officer-detail-title"
                            >
                              {item?.name}
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="officer-detail-email"
                            >
                              {item?.email}
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="officer-detail-numebr"
                            >
                              {item?.mobileNumber}
                            </Index.Typography>

                            <Index.Typography
                              component="p"
                              variant="p"
                              className="officer-detail-numebr"
                            >
                              {item?.agencyIds[0]?.vendorCode}
                            </Index.Typography>

                            <Index.Typography
                              component="p"
                              variant="p"
                              className="suspend_label"
                            >
                              {!item?.isApprove && "Restricted"}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="card-right btn-main-secondary btn-main-red btn-main-orange btn-main-yellow">
                        {roleName === "Agency" || roleName === "Department" ||
                          AgencyPermission?.Client?.permission
                            ?.read == true ? (
                          <Index.Link
                            to={`/${roleName}/vendor-management/vendorhistory/${item._id}`}
                          >
                            <Index.Tooltip
                              title="View Job History"
                              arrow
                              placement="bottom"
                              className="admin-tooltip"
                            >
                              <Index.Button
                                btnLabel="View Job History"
                                className="btn-orange"
                              >
                                <img src={PagesIndex.Svg.eyeIcon} alt="view" className="admin-icons" />
                              </Index.Button>
                            </Index.Tooltip>
                          </Index.Link>
                        ) : null}

                        <Index.Box className="mobile-more-btn">
                          <Index.Link
                            to=""
                            onClick={() => toggleMenuClass(index)}
                            className="more-btn"
                          >
                            <img src={PagesIndex.Svg.dotmenu} alt="View" />
                          </Index.Link>
                          <Index.Box
                            id="btn-toggle-popup"
                            className={
                              isActive[index] ? "toggle-pop-show" : null
                            }
                          >
                            {roleName === "Agency" || roleName === "Department" ||
                              AgencyPermission?.Client?.permission
                                ?.Suspend == true ? (
                              <Index.Tooltip
                                title={item?.isApprove ? "Restrict" : "Activate"}
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Button
                                  onClick={() =>
                                    restrictUserhandleOpen(
                                      item?._id,
                                      item?.isApprove
                                    )
                                  }
                                  btnLabel={
                                    item?.isApprove ? "Restrict" : "Activate"
                                  }
                                  className={
                                    item?.isApprove
                                      ? "btn-red bg-active"
                                      : "btn-red btn-green bg-suspend"
                                  }
                                >
                                  <img src={item?.isApprove ? PagesIndex.Svg.prohibitionIcon : PagesIndex.Svg.activeCheck} alt="Restrict" className="admin-icons" />
                                </Index.Button>
                              </Index.Tooltip>
                            ) : null}

                            {roleName === "Agency" || roleName === "Department" ||
                              AgencyPermission?.Client?.permission
                                ?.edit == true ? (
                              <Index.Tooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Button
                                  btnLabel="Edit"
                                  className="btn-secondary"
                                  onClick={() => {
                                    handleOpen();
                                    setEditUserData(item);
                                  }}
                                >
                                  <img src={PagesIndex.Svg.editNew} alt="edit" className="admin-icons" />
                                </Index.Button>
                              </Index.Tooltip>
                            ) : null}

                            {roleName === "Agency" || roleName === "Department" ||
                              AgencyPermission?.Client?.permission
                                ?.delete == true ? (
                              <Index.Tooltip
                                title="Delete"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Button
                                  btnLabel="delete"
                                  className="btn-red"
                                  onClick={() => {
                                    handleOpenDeleteNew();
                                    setDeleteUserId(item?._id);
                                  }}
                                >
                                  <img src={PagesIndex.Svg.deletewhite} alt="delete" className="admin-icons" />
                                </Index.Button>
                              </Index.Tooltip>
                            ) : null}


                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ))
                ) : (
                  <PagesIndex.NoDataFound />
                )}
              </Index.Box>
            </>
          </Index.Box>
        </>
      )
      }

      {/* Add New Vendor modal */}

      <AddNewVendor
        getUserListData={getUserListData}
        handleClose={handleClose}
        open={open}
        editUserData={editUserData}
      />

      {/* End Add New User */}
      {/* Edit Vendor Details start */}
      <EditNewVendor
        getUserListData={getUserListData}
        handleCloseEdit={handleCloseEdit}
        openEditModal={openEditModal}
        editUserData={editUserData}
      />

      {/* Restrict Vendor modal */}
      <VendorRestrict
        restrictUserModalopen={restrictUserModalopen}
        restrictUserhandleClose={restrictUserhandleClose}
        restrictUserStatus={restrictUserStatus}
        getUserListData={getUserListData}
      />
      {/* delete modal */}
      <VendorDeleteModal
        handleCloseDeleteNew={handleCloseDeleteNew}
        openDeleteNew={openDeleteNew}
        getUserListData={getUserListData}
        deleteUserId={deleteUserId}
      />
    </div >
  );
}

export default VendorManagement;
