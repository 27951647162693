import React from 'react'
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { editJobSchema } from "../../../../../validation/FormikSchema";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AdminEditJobModal = (props) => {
  const {
    adminEditJobData,
    adminEditJobModalopen,
    handleAdminEditJobModalClose,
    getJobListData
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  const initialValues = {
    title: adminEditJobData ? adminEditJobData?.title : "",
    totalOfficer: adminEditJobData ? adminEditJobData?.totalOfficer : "",
    supervisor: adminEditJobData ? adminEditJobData?.supervisor : "",
    nonSupervisor: adminEditJobData ? adminEditJobData?.nonSupervisor : "",
    pointOfContactName: adminEditJobData ? adminEditJobData?.pointOfContactName : "",
    pointOfContactNumber: adminEditJobData ? adminEditJobData?.pointOfContactNumber : "",
    rate: adminEditJobData ? adminEditJobData?.rate : "",

  };

  const handleSubmitEditJob = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("_id", adminEditJobData?._id);
    urlEncoded.append("title", values.title);
    urlEncoded.append("totalOfficer", values.totalOfficer);
    urlEncoded.append("supervisor", values.supervisor);
    urlEncoded.append("nonSupervisor", values.nonSupervisor);
    urlEncoded.append("pointOfContactName", values.pointOfContactName);
    urlEncoded.append("pointOfContactNumber", values.pointOfContactNumber);
    urlEncoded.append("rate", values.rate);

    try {
      const response = await DataService.post(
        `${PagesIndex.Api.Common.EDIT_JOB}`,
        urlEncoded
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customID",
        });

        handleAdminEditJobModalClose();
        getJobListData();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customID",
      });
      setIsLoading(false);
    }
  }

  return (
    <>
      <Index.Modal
        open={adminEditJobModalopen}
        onClose={handleAdminEditJobModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Edit Job
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={handleAdminEditJobModalClose}
              alt=""
            />
          </Index.Box>
          <PagesIndex.Formik
            enableReinitialize
            validationSchema={editJobSchema}
            initialValues={initialValues}
            onSubmit={handleSubmitEditJob}
          // innerRef={formik}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Job Title
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter job title"
                        name="title"
                        value={values?.title}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={errors.title && touched.title ? true : false}
                        helperText={
                          errors.title && touched.title ? errors.title : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Total Officer's
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        type="number"
                        className="form-control"
                        placeholder="Please enter total officer's"
                        name="totalOfficer"
                        value={values?.totalOfficer}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={errors.totalOfficer && touched.totalOfficer ? true : false}
                        helperText={
                          errors.totalOfficer && touched.totalOfficer ? errors.totalOfficer : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Supervisor
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        type="number"
                        className="form-control"
                        placeholder="Please enter supervisor"
                        name="supervisor"
                        value={values?.supervisor}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={errors.supervisor && touched.supervisor ? true : false}
                        helperText={
                          errors.supervisor && touched.supervisor ? errors.supervisor : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Non Supervisor
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        type="number"
                        className="form-control"
                        placeholder="Please enter non supervisor"
                        name="nonSupervisor"
                        value={values?.nonSupervisor}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={errors.nonSupervisor && touched.nonSupervisor ? true : false}
                        helperText={
                          errors.nonSupervisor && touched.nonSupervisor ? errors.nonSupervisor : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Point Of Contact Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter point of contact name"
                        name="pointOfContactName"
                        value={values?.pointOfContactName}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={errors.pointOfContactName && touched.pointOfContactName ? true : false}
                        helperText={
                          errors.pointOfContactName && touched.pointOfContactName ? errors.pointOfContactName : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Point Of Contact Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        type="number"
                        className="form-control"
                        placeholder="Please enter point of contact number"
                        name="pointOfContactNumber"
                        value={values?.pointOfContactNumber}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={errors.pointOfContactNumber && touched.pointOfContactNumber ? true : false}
                        helperText={
                          errors.pointOfContactNumber && touched.pointOfContactNumber ? errors.pointOfContactNumber : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Pay Rate (Per hour)
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        type="number"
                        className="form-control"
                        placeholder="Please enter pay rate"
                        name="rate"
                        value={values?.rate}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        error={errors.rate && touched.rate ? true : false}
                        helperText={
                          errors.rate && touched.rate ? errors.rate : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel="Update"
                      className="btn-primary bg-approve"
                      type="submit"
                    disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>
                    <Index.PrimaryButton
                      btnLabel="Cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => handleAdminEditJobModalClose()}
                    />
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>

  )
}

export default AdminEditJobModal;